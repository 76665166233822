<template lang="pug">
    div(class="sz-EditorExtraInfoPanel")
        div(class="sz-EditorExtraInfoPanel-options")
            div(class="sz-EditorExtraInfoPanel-options-option")
                div(class="sz-EditorExtraInfoPanel-options-option-label") {{ $t('motionAssessment.editor.timezone') }}
                div(class="sz-EditorExtraInfoPanel-options-option-selectContainer")
                    FormSectionSingleSelectSearchable(
                        title="",
                        :options="timezoneOptions",
                        :defaultSelectedOption="assessmentTimezone",
                        width="fullWidth",
                        :isSorted="false"
                        @updateSelectedOption="updateSelectedTimezone",
                        class="sz-EditorExtraInfoPanel-options-option-select"
                    )
            div(class="sz-EditorExtraInfoPanel-options-option")
                div(class="sz-EditorExtraInfoPanel-options-option-label") {{ $t('motionAssessment.editor.niosh_vli_assessment_weight.label') }}
                div(class="sz-EditorExtraInfoPanel-form-input")
                      FormSectionInputTwoWay(
                          title="",
                          textSize="noAllCaps",
                          :maxLength="maxLength",
                          :modelValue="lbsSelected ? nioshWeightLbsDisplay : nioshWeightKgsDisplay",
                          @update:modelValue="updateNioshWeight",
                          :useInputTag="true")
                div(class="sz-EditorExtraInfoPanel-body-section")
                    FormSectionToggle(
                        class="sz-ExcludeAssessmentsFilters-body-section-select",
                        title="",
                        :defaultChecked="false",
                        :options="nioshWeightUnitOptions",
                        @changed="updateNioshWeightUnits")
        div(class="sz-EditorExtraInfoPanel-button",
            :class="'sz-EditorExtraInfoPanel-button-' + saveButtonStyle",
            @click.stop="save()") {{ $t('motionAssessment.editor.reassess') }}
</template>

<script>
import FormSectionDropdownSingleSelect from 'components/Shared/FormSectionDropdownSingleSelect'
import FormSectionToggle from 'components/Shared/FormSectionToggle'
import moment from 'moment-timezone'
import EventBus from 'src/eventBus'
import constructModalSettings from 'mixins/modalSettings'
import formValidator from 'mixins/formValidator'
import constants from 'helpers/constants'
import { mapGetters } from 'vuex'
import FormSectionSingleSelectSearchable from 'components/Shared/FormSectionSingleSelectSearchable'
import { timezones } from 'helpers/timezones'
import FormSectionInputTwoWay from '../../Shared/FormSectionInputTwoWay.vue'

const kgsToLbsConversionRate = 2.2

export default {
  name: 'EditorExtraInfoPanel',

  components: {
    FormSectionDropdownSingleSelect,
    FormSectionToggle,
    FormSectionSingleSelectSearchable,
    FormSectionInputTwoWay,
  },

  mixins: [constructModalSettings, formValidator],

  props: {
    captureIdx: {
      type: [Number, String],
      required: true,
    },
    assessmentIdx: {
      type: String,
      required: true,
    },
    assessmentTimezone: {
      required: true,
      type: String,
    },
    assessmentNioshWeight: {
      required: true,
      type: Number,
    },
    assessmentStart: {
      required: true,
      type: Number,
    },
    assessmentEnd: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      timezone: undefined,
      nioshWeightKgs: this.assessmentNioshWeight,
      nioshWeightLbsDisplay: (this.assessmentNioshWeight * kgsToLbsConversionRate).toFixed(2),
      nioshWeightKgsDisplay: this.assessmentNioshWeight.toFixed(2),
      times: new Array(2),
      showConfirmModal: true,
      lbsSelected: false,
    }
  },

  computed: {
    ...mapGetters(['currentCustomer', 'currentCompany', 'workerId']),

    timezoneOptions() {
      return timezones
    },

    newSettings() {
      return {
        timezone: this.timezone,
        nioshWeight: this.nioshWeightKgs,
        startTime: this.times[constants.EDITOR.START],
        endTime: this.times[constants.EDITOR.END],
      }
    },

    saveButtonStyle() {
      return !this.assessmentChanged ? 'inactive' : 'active'
    },

    assessmentChanged() {
      return (
        this.timezone !== this.assessmentTimezone ||
        moment(this.times[constants.EDITOR.START]).format('LTS') !==
          moment
            .tz(this.assessmentStart * constants.UNIX_MILLISECONDS, this.assessmentTimezone)
            .format('LTS') ||
        moment(this.times[constants.EDITOR.END]).format('LTS') !==
          moment
            .tz(this.assessmentEnd * constants.UNIX_MILLISECONDS, this.assessmentTimezone)
            .format('LTS') ||
        /* Initial state when page is loaded this.nioshWeightKgs = undefined */
        (this.nioshWeightKgs !== undefined && this.nioshWeightKgs !== this.assessmentNioshWeight)
      )
    },

    nioshWeightUnitOptions() {
      return [
        this.$t(`motionAssessment.editor.niosh_vli_assessment_weight.units.Kg`),
        this.$t(`motionAssessment.editor.niosh_vli_assessment_weight.units.Lbs`),
      ]
    },
  },

  watch: {
    showConfirmModal: {
      handler: function () {
        if (!this.showConfirmModal) {
          this.saveClicked()
        }
      },
      deep: true,
    },
  },

  mounted() {
    EventBus.$on('UPDATE_ANNOTATION_POSITION_EDITOR', (params) => {
      this.setTime(params.timestamp, params.type)
    })

    EventBus.$on('USER_CONFIRMED', () => {
      this.showConfirmModal = false
    })

    EventBus.$on('USER_CANCELLED', () => {
      this.showConfirmModal = true
    })

    this.setTime(
      moment.tz(this.assessmentStart * constants.UNIX_MILLISECONDS, this.assessmentTimezone),
      constants.EDITOR.START
    )
    this.setTime(
      moment.tz(this.assessmentEnd * constants.UNIX_MILLISECONDS, this.assessmentTimezone),
      constants.EDITOR.END
    )
  },

  beforeDestroy() {
    EventBus.$off('UPDATE_ANNOTATION_POSITION_EDITOR')

    EventBus.$off('USER_CONFIRMED')

    EventBus.$off('USER_CANCELLED')
  },

  methods: {
    updateSelectedTimezone(newTimezone) {
      this.timezone = newTimezone
    },

    async updateNioshWeight(newWeight) {
      if (this.isNioshWeightValid(newWeight)) {
        if (this.lbsSelected) {
          newWeight = newWeight / kgsToLbsConversionRate
        }
        /* Only update valid values */
        this.nioshWeightKgs = parseFloat(newWeight)
        this.nioshWeightKgsDisplay = this.nioshWeightKgs
        this.nioshWeightLbsDisplay = this.nioshWeightKgs * kgsToLbsConversionRate
      } else if (newWeight !== '') {
        /* Do not show invalid modal if user deletes form value */
        await this.failModal(
          this.$t('motionAssessment.editor.niosh_vli_assessment_weight.enterValidWeight'),
          this.$t('motionAssessment.editor.niosh_vli_assessment_weight.invalidWeight'),
          () => (this.showConfirmation = true)
        )
      } else if (newWeight == '') {
        /* Reset NIOSH weight */
        this.nioshWeightKgs = this.assessmentNioshWeight
      }
    },

    isNioshWeightValid(inputNioshAssessmentWeight) {
      let inputNioshAssessmentWeightFloat = parseFloat(inputNioshAssessmentWeight)
      if (isNaN(inputNioshAssessmentWeightFloat)) {
        return false
      } else {
        return inputNioshAssessmentWeightFloat >= 1.0
      }
    },

    setTime(time, type) {
      this.$set(this.times, type, time)
    },

    async updateNioshWeightUnits(lbsSelected) {
      this.lbsSelected = lbsSelected
    },

    async saveClicked() {
      try {
        // Refuse the update if the assessment is currently being processed
        let isProcessing = await this.isAssessmentProcessing()
        if (isProcessing) {
          this.failModal(
            this.$t(`motionAssessment.editor.reassessmentInProgress`),
            this.$t(`somethingWentWrong`),
            () => (this.showConfirmModal = true)
          )
        } else {
          this.updateReassessment()
          this.successModal(this.$t(`motionAssessment.editor.successMessage`), true, () =>
            EventBus.$emit('UPDATE_ASSESSMENT')
          )
        }
      } catch (error) {
        this.failModal(
          this.$t('pleaseTryAgain'),
          this.$t('somethingWentWrong'),
          () => (this.showConfirmModal = true)
        )
        console.log('error changing timezone')
        console.log(error)
        throw new Error(error.message)
      }
    },

    async save() {
      if (!this.assessmentChanged) return

      if (this.showConfirmModal) {
        this.confirmModal(
          this.$t('motionAssessment.editor.confirmMessage.title'),
          this.$t('motionAssessment.editor.confirmMessage.message'),
          this.newSettingsMessageList()
        )
      }
    },

    async updateReassessment() {
      if (this.assessmentChanged) {
        await this.$store.dispatch('makeReAssessment', {
          currentCustomer: this.currentCustomer,
          currentCompany: this.currentCompany,
          workerId: this.workerId,
          captureIdx: this.captureIdx,
          assessmentIdx: this.assessmentIdx,
          details: {
            // If either of the timestamps weren't changed,
            // it remains in a native Moment format so we need to convert it to a millisecond timestamp
            start: parseInt(
              moment.tz(this.newSettings.startTime, this.assessmentTimezone).format('x')
            ),
            end: parseInt(moment.tz(this.newSettings.endTime, this.assessmentTimezone).format('x')),
            timezone: this.newSettings.timezone,
            nioshWeight: this.newSettings.nioshWeight,
          },
        })
      }
    },

    newSettingsMessageList() {
      let list = []

      Object.keys(this.newSettings).map((option) => {
        if (this.newSettings[option] !== null && this.newSettings[option] !== undefined) {
          this.setMessage(list, option)
        }
      })
      return list
    },

    setMessage(list, option) {
      let title = this.$t(`motionAssessment.editor.${option}`)
      let settingValue = this.newSettings[option]

      switch (option) {
        case constants.EDITOR.CHANGE_OPTIONS.START_TIME:
        case constants.EDITOR.CHANGE_OPTIONS.END_TIME:
          list.push(`${title}: ${moment.tz(settingValue, this.assessmentTimezone).format('LTS')}`)
          break

        default:
          list.push(`${title}: ${settingValue}`)
          break
      }
    },

    async isAssessmentProcessing() {
      return (await this.$store.dispatch('getAssessmentProgress', this.captureIdx)).inProgress
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~design';
input:focus {
  outline: none;
}

.sz-EditorExtraInfoPanel {
  &-button {
    @extend %button-layout;
    @extend %font-heading;
    background-color: $color-lifebooster-light-green;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-radius: 0;
    color: white;
    display: flex;
    justify-content: center;

    &-inactive {
      background-color: $color-unselected-text;
      cursor: not-allowed;
      user-select: none;
    }
  }
  &-form {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;

    &-input {
      width: 100%;
      margin-left: 0.5rem;
      text-align: left;
    }
  }
  &-options {
    margin-bottom: 1rem;
    &-option {
      display: flex;
      align-items: center;
      margin: 0.5rem 0;
      &-label {
        @extend %font-topbar-heading;
        font-size: 0.85rem;
        width: auto; // Make the label's width automatically adjust to the content
        text-align: left;
        &-timezone {
          width: 5.5rem;
        }
      }

      &-selectContainer {
        width: 100%;
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
