<template lang="pug">
    div(class="sz-CharacterViewerPanel")
        div(class="sz-CharacterViewerPanel-assessmentID")
          CopyableText(
            :text="assessmentID",
            class="resize")
        AssessmentDialogBox(
            v-if="!isEditor",
            :loadingGraph="loadingGraph",
            :selectedRisks="selectedRisks",
            class="sz-CharacterViewerPanel-stretchBox resize")
        component(
            :is="getModuleComponent.component",
            v-bind="getModuleComponent.props",
            class="resize")
        CharacterViewerController(
            v-if="!isEditor",
            :assessmentStart="assessmentStart",
            :assessmentEnd="assessmentEnd",
            :loadingGraph="loadingGraph",
            :assessmentTimezoneOffset="assessmentTimezoneOffset",
            :assessmentIdx="assessmentIdx",
            :captureIdx="captureIdx",
            :indexKey="indexKey",
            @refreshGraph="refreshGraph",
            class="resize")
        CharacterViewer(
            v-if="showCharacter"
            :quatKeys="quatKeys",
            :jointKeys="jointKeys",
            :loadingGraph="loadingGraph",
            :loadingAssessment="loadingAssessment",
            class="sz-CharacterViewerPanel-character resize")
        div(
            :loadingGraph="loadingGraph",
            class="sz-CharacterViewerPanel-stretchBox resize")
</template>
<script>
import CharacterViewer from 'components/Assessment/CharacterViewer'
import CharacterViewerController from 'components/Assessment/CharacterViewerController'
import AssessmentDialogBox from 'components/Assessment/AssessmentDialogBox'
import DailyNote from 'components/Assessment/DailyNote'
import EditorExtraInfoPanel from 'components/Assessment/Editor/EditorExtraInfoPanel'
import RightPanelVibration from 'components/Assessment/VibrationAssessment/RightPanelVibration'
import RightPanelThermal from 'components/Assessment/ThermalAssessment/RightPanelThermal'
import { mapGetters } from 'vuex'
import constants from 'helpers/constants'
import FormSectionToggle from 'components/Shared/FormSectionToggle'
import constructModalSettings from 'mixins/modalSettings'
import formValidator from 'mixins/formValidator'
import { userIsLBAssessor } from 'helpers/permissionsHelper'
import { getInstance } from 'auth/index'
import CopyableText from 'components/Shared/CopyableText'

export default {
  name: 'RightPanelModule',

  components: {
    CharacterViewer,
    CharacterViewerController,
    AssessmentDialogBox,
    DailyNote,
    EditorExtraInfoPanel,
    RightPanelThermal,
    RightPanelVibration,
    FormSectionToggle,
    CopyableText,
  },

  mixins: [formValidator, constructModalSettings],

  props: {
    loadingGraph: {
      required: true,
      type: Boolean,
    },

    loadingAssessment: {
      required: false,
      type: Boolean,
    },

    assessmentStart: {
      required: true,
      type: Number,
    },

    assessmentEnd: {
      required: true,
      type: Number,
    },

    assessmentTimezone: {
      required: true,
      type: String,
    },

    assessmentNioshWeight: {
      required: true,
      type: Number,
    },

    assessmentTimezoneOffset: {
      required: true,
      type: Number,
    },

    captureIdx: {
      type: [Number, String],
      required: true,
    },

    metadataID: {
      type: String,
      required: true,
    },

    assessmentIdx: {
      type: [Number, String],
      required: true,
    },

    indexKey: {
      required: true,
      type: String,
    },

    quatKeys: {
      required: true,
      type: Object,
    },

    jointKeys: {
      required: true,
      type: Object,
    },

    selectedRisks: {
      required: true,
      type: Array,
    },
  },

  computed: {
    ...mapGetters(['selectedModule', 'selectedAssessment']),

    getModuleComponent() {
      if (!this.selectedModule in constants.ASSESSMENT_MODULE_COMPONENT_MAP) return
      return {
        component: constants.ASSESSMENT_MODULE_COMPONENT_MAP[this.selectedModule],
        props: this.getProps(),
      }
    },

    showCharacter() {
      return (
        this.selectedModule === constants.ASSESSMENT_PAGE.MOTION ||
        this.selectedModule === constants.ASSESSMENT_PAGE.POSTURE ||
        this.selectedModule === constants.ASSESSMENT_PAGE.EDITOR
      )
    },

    isEditor() {
      return constants.ASSESSMENT_PAGE.EDITOR === this.selectedModule
    },

    isLBAssessor() {
      return userIsLBAssessor(getInstance().user)
    },

    assessmentID() {
      if (!this.assessmentIdx) return ''

      return `${constants.MODULE_ASSESSMENT_ID_MAP[this.selectedModule]}-${this.assessmentIdx}`
    },
  },

  methods: {
    getProps() {
      switch (this.selectedModule) {
        case constants.ASSESSMENT_PAGE.VIBRATION:
          return {}
        case constants.ASSESSMENT_PAGE.THERMAL:
          return {}
        case constants.ASSESSMENT_PAGE.EDITOR:
          return {
            captureIdx: this.captureIdx,
            assessmentIdx: this.assessmentIdx,
            assessmentTimezone: this.assessmentTimezone,
            assessmentNioshWeight: this.assessmentNioshWeight,
            assessmentStart: this.assessmentStart,
            assessmentEnd: this.assessmentEnd,
          }
        default:
          break
      }
    },

    refreshGraph() {
      this.$emit('refreshGraph')
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~design';
.sz-CharacterViewerPanel {
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  min-width: 18rem;

  &-assessmentID {
    align-self: flex-start;
    width: 96%;
    padding-bottom: 1rem;
    font-size: 14.25px;
  }

  &-stretchBox {
    flex: 1 1 1px;
    min-height: 4rem;
    width: 100%;
  }

  &-character {
    width: 100%;
    padding-top: 0.5rem;
  }

  &-exclusion {
    padding-bottom: 1rem;
  }
}

.resize {
  @media only screen and (min-height: 500px) {
    width: 80%;
  }

  @media only screen and (min-height: 600px) {
    width: 90%;
  }
}
</style>
