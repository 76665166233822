<template lang="pug">
  div(
      class="sz-FormSectionInputTwoWay")
      div(class="sz-FormSectionInputTwoWay-label")
          span(
              v-if="required",
              class="sz-FormSectionInputTwoWay-label-required") {{ $t('form.required') }}
          label(
              :for="`formSectionInput-${title}`",
              class="sz-FormSectionInputTwoWay-label",
              :class="{'sz-FormSectionInputTwoWay-label-center': centerTitle}") {{ title }}
      textarea(
          v-if="!useInputTag",
          :placeholder="placeholder",
          v-model="internalValue",
          :maxlength="maxLength",
          @input="onInput",
          @keyup.enter.stop="onEnter",
          class="sz-FormSectionInputTwoWay-input",
          :id="`formSectionInput-${title}`")
      input(
          v-if="useInputTag",
          :placeholder="placeholder",
          v-model="internalValue",
          :maxlength="maxLength",
          @input="onInput",
          @keyup.enter.stop="onEnter",
          class="sz-FormSectionInputTwoWay-input",
          :readonly="disableInput",
          :class="[`sz-FormSectionInputTwoWay-input-${textSize}`, {readonly : disableInput}]"
          :id="`formSectionInput-${title}`")
</template>

<script>
export default {
  name: 'FormSectionInputTwoWay',

  props: {
    modelValue: {
      required: true,
      type: String,
      default: '',
    },
    title: {
      required: true,
      type: String,
    },
    placeholder: {
      required: false,
      type: String,
      default: '',
    },
    centerTitle: {
      required: false,
      type: Boolean,
      default: false,
    },
    maxLength: {
      required: false,
      type: Number,
      default: 10000,
    },
    useInputTag: {
      required: false,
      type: Boolean,
      default: false,
    },
    disableInput: {
      required: false,
      type: Boolean,
      default: false,
    },
    textSize: {
      required: false,
      type: String,
      default: '',
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  computed: {
    internalValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },

  methods: {
    onInput() {
      /* OnInput placeholder method */
    },

    onEnter() {
      this.$emit('enter')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~design';

.sz-FormSectionInputTwoWay {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  &-label {
    @extend %font-topbar-heading;
    display: flex;
    font-size: 12px;
    color: $color-white;

    &-center {
      align-self: center;
    }

    &-required {
      color: red;
    }
  }

  input {
    width: 70%;
  }

  textarea.sz-FormSectionInputTwoWay-input {
    outline: none;
    padding-top: 1rem;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom: 1px $color-lifebooster-light-green solid;
    background-color: inherit;
    @extend %font-topbar-heading;
    @include scrollbar-widget;
    font-size: 18px;
    color: $color-white;
    resize: none;
    width: 95%;
  }

  input.sz-FormSectionInputTwoWay-input {
    outline: none;
    padding-top: 1rem;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom: 1px $color-lifebooster-light-green solid;
    background-color: inherit;
    padding: 0.3rem 0;
    @extend %font-topbar-heading;
    @include scrollbar-widget;
    font-size: 18px;
    color: $color-white;
    resize: none;
    width: 95%;

    &-content {
      @extend %font-content;
      font-size: 12px;
    }

    &-noAllCaps {
      text-transform: none;
    }

    &.readonly {
      cursor: not-allowed;
      color: #999999;
    }
  }
}
</style>
